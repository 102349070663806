.animated-button {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px;
  padding: 1px 30px;
  color: #212121;
  border: .5px solid;
  border-color: transparent;
  font-size: 18px;
  background-color: inherit;
  border-radius: 10px;
  font-weight: 500;
  box-shadow: 0 0 0 1px rgb(121, 121, 121);
  cursor: pointer;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button svg {
  position: absolute;
  width: 24px;
  z-index: 9;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button .arr-1 {
  left: 10px; /* Changed from right to left */
}

.animated-button .arr-2 {
  right: -25%; /* Changed from left to right */
}

.animated-button .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 50%;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button .text {
  position: relative;
  z-index: 1;
  transform: translateX(12px); /* Adjusted for opposite direction */
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button:hover {
  box-shadow: 0 0 0 12px transparent;
  color: #dbdbdb;
}

.animated-button:hover .arr-1 {
  left: -25%; /* Changed hover direction */
}

.animated-button:hover .arr-2 {
  right: 10px; /* Changed hover direction */
}

.animated-button:hover .text {
  transform: translateX(-12px); /* Adjusted for opposite direction */
}

.animated-button:hover svg {
  fill: #212121;
}

.animated-button:active {
  scale: 0.95;
  box-shadow: 0 0 0 4px rgb(221, 221, 221);
}

.animated-button:hover .circle {
  width: 220px;
  height: 220px;
  opacity: 1;
}
