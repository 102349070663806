.bodyWrapper {
  display: flex; /* Use flex to position children */
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.ozloowlImage {
  max-width: 450px; /* Set the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-left: -1.5rem;
}

.container {
    display: flex; /* Make the container a flex container */
    flex-direction: row; /* Arrange children in a row */
    align-items: center; /* Center children vertically */
    max-width: 900px; /* Increase the width to hold the image and form side by side */
    background: #F8F9FD;
    background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(244, 247, 251) 100%);
    border-radius: 40px;
    border: 5px solid rgb(255, 255, 255);
    box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 30px 30px -20px;
    padding: .2rem
}

/* Add a wrapper around the form and other elements to control their layout */
.form-wrapper {
  flex-grow: 1; /* Allow the wrapper to take up the remaining space */
  margin-left: 1.5rem; /* Add space between the image and the form */
}

  .form .input {
    width: 100%;
    min-width: 250px;
    font-size: 15px;
    color: rgb(75, 75, 75);
    background: white;
    border: none;
    padding: 15px 20px;
    border-radius: 20px;
    margin-top: 2rem;
    box-shadow: #cff0ff 0px 10px 10px -5px;
    border-inline: 2px solid transparent;
  }

  .form .input::-moz-placeholder {
    color: rgb(170, 170, 170);
  }

  .form .input::placeholder {
    color: rgb(170, 170, 170);
  }

  .form .input:focus {
    outline: none;
    border-inline: 2px solid #12B1D1;
  }

  .form .forgot-password {

    display: block;
    margin-top: 10px;
    margin-left: 10px;
  }

  .form .forgot-password a {
    font-size: 11px;
    color: #0099ff;
    text-decoration: none;
  }

  .form .login-button {
    display: block;
    width: 100%;
    font-weight: bold;
    background: linear-gradient(45deg, rgb(16, 137, 211) 0%, rgb(18, 177, 209) 100%);
    color: white;
    padding-block: 15px;
    margin: 20px auto;
    border-radius: 20px;
    box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 20px 10px -15px;
    border: none;
    transition: all 0.2s ease-in-out;
  }

  .form .login-button:hover {
    transform: scale(1.03);
    box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 23px 10px -20px;
  }

  .form .login-button:active {
    transform: scale(0.95);
    box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 15px 10px -10px;
  }

  .social-account-container {
    margin-top: 25px;
  }

  .social-account-container .title {
    display: block;
    text-align: center;
    font-size: 10px;
    color: rgb(170, 170, 170);
  }

  .social-account-container .social-accounts {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 5px;
  }

  .social-account-container .social-accounts .social-button {
    max-width: 320px;
    display: flex;
    padding: 0.5rem 1.4rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.25);
    gap: 0.75rem;
    color: rgb(65, 63, 63);
    background-color: #fff;
    cursor: pointer;
    transition: all .6s ease;
  }

  .social-account-container .social-accounts .social-button .svg {
    fill: white;
    margin: auto;
    height: 2em; /* Adjust the height as needed */
    width: 2em;  /* Adjust the width as needed */
  }

  .social-account-container .social-accounts .social-button:hover {
    transform: scale(1.05);
  }

  .social-account-container .social-accounts .social-button:active {
    transform: scale(1);
  }

  .agreement {
    display: block;
    text-align: center;
  }

  .agreement a {
    text-decoration: none;
    color: #0099ff;
    font-size: 9px;
  }
  .toggle-auth-container {
    display: flex;
    justify-content: center;
  }