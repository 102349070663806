


  .logo {
    position: relative;
    border-radius: 10px;
    width: 50px;
    height: 40px;
    transform: scale(.9);
    object-fit: contain;
    transition: transform 0.5s ease;
    margin-left: -1rem;
    margin-top: -1px;

  }
